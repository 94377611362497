.MuiPaper-root {
  background-color: rgba(0, 0, 0, 0) !important;
}

.MuiPaper-root.MuiExpansionPanel-root.MuiPaper-elevation1 {
  margin-bottom: 2%;

  &:last-child {
    margin-bottom: 0%;
  }
}

.ShortDetails {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & div {
    & h3 {
      font-size: 1.8rem;
      @include tablet {
        font-size: 1.6rem;
      }

      @include phone {
        font-size: 1.4rem;
      }
    }

    & p {
      margin: 3% 0;
      font-size: 1.1rem;

      @include phone {
        font-size: 0.9rem;
      }
    }
  }

  & .Project-links {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include phone {
      flex-direction: column;
    }

    & a {
      text-decoration: none;
      font-weight: 600;
      font-size: 1.1rem;
      padding: 0.5rem 0.5rem;
      border-radius: 10%;

      @include phone {
        font-size: 0.9rem;
      }
    }
  }
}

.Description {
  display: flex;
  flex-direction: column;

  & p {
    font-size: 1.3rem;
    margin: 2% 0;

    @include phone {
      font-size: 1rem;
    }
  }
  .Project-images {
    display: flex;
  }

  & img {
    margin: 2% auto;

    @include tablet {
      display: none;
    }
  }
}
