@mixin tablet {
  @media (max-width: 950px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 650px) {
    @content;
  }
}
