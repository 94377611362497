.DrawerMenu {
  height: 100vh;
  width: 70vw;
  top: 0;
  position: fixed;
  z-index: 100;

  & svg {
    cursor: pointer;
    width: 2em;
    height: 2em;
  }

  & .Drawer-links {
    display: flex;
    flex-direction: column;
    margin-top: 10%;

    & .Drawer-link-item {
      & a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 5% 0;
        font-size: 1.5rem;
        text-decoration: none;
        padding-left: 10%;

        & svg {
          margin-right: 2%;
          height: 1.3em;
          width: 1.3em;
        }
      }
    }
  }
}
