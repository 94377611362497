//Lightmode theme
body {
  background-color: $bg-color;
  transition: all 0.8s ease-in-out;

  & h1,
  h2,
  h3,
  h4 {
    color: $header-text;
  }

  & p {
    color: $normal-text;
  }

  & span {
    color: $highligt-color;
  }

  & svg.MuiSvgIcon-root {
    fill: $link-text;
  }

  & .Nav-links a.active {
    text-decoration: underline;
    text-decoration-color: $link-text;
  }

  & .Nav-links a {
    color: $Grey-link;

    &:hover {
      color: $Grey-link-hover;
      background-color: $Grey-bg-hover;
    }
  }

  & a.links {
    color: $link-text;

    &:hover {
      color: $white-text-hover;
      background-color: $link-text;
    }
  }

  & .DrawerMenu {
    background: $bg-color-darkmode;

    & svg {
      fill: $link-text-darkmode;
    }

    & .Drawer-links a.active {
      text-decoration: underline;
      text-decoration-color: $link-text-darkmode;
    }

    & .Drawer-links > .Drawer-link-item {
      & a {
        color: $Grey-link;

        &:hover {
          color: $Grey-link-hover;
          background-color: $Grey-bg-hover;
        }
      }
    }

    & .Darkmode-container {
      & .link-text {
        color: $Grey-link;
      }

      &:hover {
        color: $Grey-link-hover;
        background-color: $Grey-bg-hover;
      }
    }
  }
}
//Darkmode theme
body.darkmode {
  background-color: $bg-color-darkmode;

  & h1,
  h2,
  h3,
  h4 {
    color: $header-text-darkmode;
  }

  & p {
    color: $normal-text-darkmode;
  }

  & span {
    color: $highligt-color;
  }

  & svg {
    fill: $link-text-darkmode;
  }
  & div.MuiExpansionPanelSummary-root {
    background-color: $bg-color-darkmode;
    transition-duration: 0.8s;
    transition-property: background-color;
    transition-timing-function: ease-in;
  }

  & div.MuiExpansionPanelDetails-root {
    background-color: $bg-color-darkmode;
    transition-duration: 0.8s;
    transition-property: background-color;
    transition-timing-function: ease-in;
  }

  & .Nav-links a.active {
    text-decoration: underline;
    text-decoration-color: $link-text-darkmode;
  }

  & a.links {
    color: $link-text-darkmode;

    &:hover {
      color: $white-text-hover;
      background-color: $link-text-darkmode;
    }
  }

  & .DrawerMenu {
    background: $bg-color;

    & svg {
      fill: $link-text;
    }

    & .Drawer-links a.active {
      text-decoration: underline;
      text-decoration-color: $link-text;
    }

    & .Drawer-links > .Drawer-link-item > a {
      color: $Grey-link;

      &:hover {
        color: $Grey-link-hover;
        background-color: $Grey-bg-hover;
      }
    }

    & .Darkmode-container {
      & .link-text {
        color: $Grey-link;
      }

      &:hover {
        color: $Grey-link-hover;
        background-color: $Grey-bg-hover;
      }
    }
  }
}
