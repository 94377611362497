//Lightmode
//Background Color
$bg-color: whitesmoke;

//General Text Colors
$normal-text: #555;
$header-text: #222;
$link-text: #3f51b5;
$white-text-hover: #fff;
$highligt-color: #ec18ee;

//Nav Colors
$Grey-link: #666;
$Grey-bg-hover: #ddd;
$Grey-link-hover: #444;

//Darkmode
//Background Color
$bg-color-darkmode: #222;

//General Text Colors
$normal-text-darkmode: #ddd;
$header-text-darkmode: floralwhite;
$link-text-darkmode: coral;
$white-text-hover: #fff;

//Fonts
$font-text: "Roboto", sans-serif;
$font-header: "Lato", sans-serif;
