h1,
h2,
h3,
h4 {
  font-family: $font-header;
  line-height: 1rem;
  letter-spacing: 0.04em;

  @include phone {
    line-height: 2rem;
  }
}

p,
a,
span {
  font-family: $font-text;
  line-height: 2rem;
  letter-spacing: 0.06em;

  @include phone {
    line-height: 1.5rem;
  }
}
