.ScrollBtn-Container {
  cursor: pointer;
  position: fixed;
  right: 1.5em;
  bottom: 2.3em;

  .Arrow {
    transform: rotate(180deg);
  }

  svg.MuiSvgIcon-root.Arrow {
    width: 2em;
    height: 2em;

    @include phone {
      width: 1.3em;
      height: 1.3em;
    }
  }

  @include phone {
    right: 47.5%;
  }
}
