.ProjectList {
  margin: 0 0;

  & h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 5%;

    @include phone {
      font-size: 1.6rem;
    }
  }
}
