p {
  font-size: 1.5rem;
  margin: 5% 5%;

  @include tablet {
    font-size: 1.3rem;
  }
  @include phone {
    font-size: 1.2rem;
  }
}

h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 5%;

  @include phone {
    font-size: 1.6rem;
  }
}
