.Contact-container {
  margin: 0 5%;

  & p {
    font-size: 1.5rem;
    margin: 5% 0%;

    @include tablet {
      font-size: 1.3rem;
    }
    @include phone {
      font-size: 1.2rem;
    }
  }

  & .Contact-links {
    display: flex;
    justify-content: space-around;

    & a {
      text-decoration: none;
    }

    & svg {
      width: 3rem;
      height: 3rem;
      transition: transform 0.3s ease-in-out;

      @include phone {
        width: 2.4rem;
        height: 2.4rem;
      }

      &:hover {
        transform: scale(1.3);

        @include phone {
          transform: scale(1.1);
        }
      }
    }
  }
}
