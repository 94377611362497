.Darkmode-container {
  & p {
    display: none;
    @include phone {
      display: block;
      cursor: pointer;
      font-size: 1.5rem;
      margin: 5% 0;
    }
  }

  @include phone {
    display: flex;
    align-items: center;
    height: 2em;
    margin: 5% 0;
    padding-left: 10%;
    & svg.Darkmode-icon {
      margin-right: 2%;
      height: 1.3em;
      width: 1.3em;
    }
  }
}
