.Header {
  display: flex;
  margin: 5% 0;
  justify-content: space-evenly;

  & .Header-text {
    margin: 0 5%;
    & h1 {
      font-size: 3rem;

      @include tablet {
        font-size: 2.5rem;
      }
      @include phone {
        font-size: 2rem;
      }
    }
    & p {
      font-size: 1.5rem;
      margin: 5% 0;

      @include tablet {
        font-size: 1.3rem;
      }
      @include phone {
        font-size: 1.2rem;
      }
    }
  }

  & img {
    margin: auto auto;
    margin-right: 5%;
    width: 300px;
    height: auto;

    @include tablet {
      display: none;
    }
  }
}

.Header-links {
  display: flex;
  justify-content: space-around;
  margin-bottom: 7%;

  & a {
    text-decoration: none;
  }

  & svg {
    width: 3rem;
    height: 3rem;
    transition: transform 0.3s ease-in-out;

    @include phone {
      width: 2.4rem;
      height: 2.4rem;
    }

    &:hover {
      transform: scale(1.3);

      @include phone {
        transform: scale(1.1);
      }
    }
  }
}
